import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/knowledge-base'
class KnowledgeBaseService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    search(query){
        return http.post(URL + '/search-issue', {search_text: query})
    }
}

export default new KnowledgeBaseService()